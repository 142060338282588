// http://scg.ar-ch.org/

:root {
    --primary-lighten-7: #eff7fe !important; // 60%
    --primary-lighten-6: #c0dffa !important; // 50%
    --primary-lighten-5: #4ba2ef !important; // 25%
    --primary-lighten-4: #3496ed !important; // 20%
    --primary-lighten-3: #1c8aeb !important; // 15%
    --primary-lighten-2: #137ddb !important; // 10%
    --primary-lighten-1: #116fc3 !important; // 5%
    --primary: #0F62AC !important; // 0%
    --primary-darken-4: #0d5595 !important; // 5%
    --primary-darken-3: #0b477d !important; // 10%
    --primary-darken-2: #093a66 !important; // 15%
    --primary-darken-1: #072d4e !important; // 20%
}